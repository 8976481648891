






















import { Component } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';
import ExpenseComponent from '../components/ExpenseComponent.vue';
import ExpensesTableComponent from '../components/ExpensesTableComponent.vue';
import ErrorComponent from '../components/ErrorComponent.vue';
import DialogComponent from '../components/DialogComponent.vue';
import { handleError } from '../lib/shared';
import * as _ from 'lodash';
import { SearchExpenses } from '@/store/models';
import { EmptySearchExpenses } from '@/store/models-empty';

@Component({
    components: {
      ErrorComponent,
      DialogComponent,
      ExpenseComponent,
      ExpensesTableComponent,
    },
  })
export default class PayeeExpenses extends BaseComponent {

  public arrErrors: Error[] = [];
  protected baseRoute: string = '/payees';

  private projectsSearchCriteria: SearchExpenses = _.cloneDeep(EmptySearchExpenses);

  private payeeId: string = '';
  private bAddExpense: boolean = false;

  private updateExpenses: number = 0;
  private updateExpense: number = 0;


  protected mounted() {
    this.mount();
  }

  protected newExpenseItem() {
    this.bAddExpense = true;
  }

  private closeExpense() {
    this.bAddExpense = false;
    this.changeRoute(this.baseRoute);
  }

  private saveExpense() {
    this.refreshExpense();
    this.refreshExpenses();
  }

  private refreshExpense() {
    this.updateExpense++;
  }

  private refreshExpenses() {
    this.updateExpenses++;
    this.mount();
  }

  private mount() {
    if (this.$route.path.endsWith('new-expense')) {
      this.newExpenseItem();
    }
    this.payeeId = this.$route.params.id;
    this.baseRoute = '/payees/id/' + this.payeeId + '/expenses';
  }

  private handleError(error) {
    handleError(this.arrErrors, error);
  }

}
