









































































































import { Component, Watch, Prop } from 'vue-property-decorator';
import BaseComponent from './BaseComponent';
import ExpenseHelper from './ExpenseHelper';
import ExpenseComponent from './ExpenseComponent.vue';
import ErrorComponent from './ErrorComponent.vue';
import DeleteModalComponent from './DeleteModalComponent.vue';
import SpinnerComponent from './SpinnerComponent.vue';
import DatePickerComponent from './DatePickerComponent.vue';
import DialogComponent from './DialogComponent.vue';
import FiltersComponent from './FiltersComponent.vue';
import MobileBottomNavComponent from "../components/MobileBottomNavComponent.vue";
import { searchExpenses, deleteExpense } from '../store/rest.service';
import { GeneralSearchCriteria, ReferenceData, ResourceSearchResult, SearchExpenses, SortColumnMapping } from '../store/models';
import { EmptyGeneralSearchCriteria, EmptySearchExpenses } from '../store/models-empty';
import { getColorClass } from '../lib/colors';
import { getIconUrl } from '../lib/images';
import { handleError } from '../lib/shared';
import * as _ from 'lodash';
import { getExpenseAreaFilter, setExpenseSearchFacets } from '@/lib/facets';
import PayeeHelper, { VendorCategory } from './PayeeHelper';

@Component({
  components: {
    ExpenseComponent,
    ErrorComponent,
    SpinnerComponent,
    DeleteModalComponent,
    DatePickerComponent,
    DialogComponent,
    FiltersComponent,
    MobileBottomNavComponent,
  },
})
export default class ExpensesTableComponent extends BaseComponent {

  @Prop() public isContribution: any;
  @Prop() public projectId: any;
  @Prop() public payeeId: any;
  @Prop() public beneficiaryId: any;
  @Prop() public isContainer: any;
  @Prop() public isNestedContent: any;
  @Prop() public hideFacets: any;

  private debounceSearch = _.debounce(this.getExpenses, 500);

  private helper: ExpenseHelper = new ExpenseHelper();
  private helperLoading: boolean = true;
  private payeeHelper: PayeeHelper = new PayeeHelper(VendorCategory.None);
  private payeeHelperLoading: boolean = true;

  private editedId: string = '';
  private newShowExpense: number = 0;

  private expenses: ResourceSearchResult|null = null;
  private expensesLoading: boolean = false;
  private arrErrors: Error[] = [];

  private bInitialLoad: boolean = true;
  private bInitialLoadWatch: boolean = true;
  private bEditExpense: boolean = false;

  private searchCriteria: GeneralSearchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);
  private pagination: any = { sortBy: 'common.displayName', descending: false, page: 1, rowsPerPage: 25 };

  private showForm: boolean = false;
  private showSearchForm: boolean = false;
  private resetDate: number = 0;
  private rowsPerPage: number[] = [10, 25, 100];
  private searchTerms: string = '';
  private expensesSearchCriteria: SearchExpenses =  _.cloneDeep(EmptySearchExpenses);

  private getColorClass = getColorClass;

  private bDeleteExpenseModal: boolean = false;
  private deleteExpenseId: string = '';
  private deleteExpenseName: string = '';
  private deleteExpenseEtag: string = '';

  private projectHeaders: any[] = [
    { text: 'Description', align: 'left', sortable: true, value: 'payment.description', class: 'grid-header' },
    { text: 'Spend', align: 'left', sortable: true, value: 'payment.amount', class: 'grid-header' },
    { text: 'Expense Class', align: 'left', sortable: true, value: 'expense.expenseClass', class: 'grid-header' },
    { text: 'Date', align: 'left', sortable: true, value: 'payment.effectiveDate', class: 'grid-header' },
    { text: 'Delete', align: 'left', sortable: false, class: 'grid-header' },
  ];

  private beneficiaryHeaders: any[] = [
    { text: 'Description', align: 'left', sortable: true, value: 'payment.description', class: 'grid-header' },
    { text: 'Payee Name', align: 'left', sortable: true, value: 'payment.hasPayee.displayName', class: 'grid-header' },
    { text: 'Spend', align: 'left', sortable: true, value: 'payment.amount', class: 'grid-header' },
    { text: 'Expense Class', align: 'left', sortable: true, value: 'expense.expenseClass', class: 'grid-header' },
    { text: 'Date', align: 'left', sortable: true, value: 'payment.effectiveDate', class: 'grid-header' },
    { text: 'Delete', align: 'left', sortable: false, class: 'grid-header' },
  ];

  private payeeHeaders: any[] = [
    { text: 'Project Name', align: 'left', sortable: true, value: 'payment.hasProject', class: 'grid-header' },
    { text: 'Description', align: 'left', sortable: true, value: 'payment.description', class: 'grid-header' },
    { text: 'Spend', align: 'left', sortable: true, value: 'payment.amount', class: 'grid-header' },
    { text: 'Expense Class', align: 'left', sortable: true, value: 'expense.expenseClass', class: 'grid-header' },
    { text: 'Date', align: 'left', sortable: true, value: 'payment.effectiveDate', class: 'grid-header' },
    { text: 'Delete', align: 'left', sortable: false, class: 'grid-header' },
  ];

  private benefitHeaders = [
    { text: 'Community', align: 'left', sortable: true, value: 'default', class: 'grid-header' },
    { text: 'Type', align: 'left', sortable: false, class: 'grid-header' },
    { text: 'Submitter', align: 'left', sortable: false, class: 'grid-header' },
    { text: 'Date', align: 'left', sortable: false, class: 'grid-header' },
    { text: 'Amount', align: 'left', sortable: false, class: 'grid-header' },
    { text: 'Delete', align: 'left', sortable: false, class: 'grid-header' },
  ];

  private sortMappings: SortColumnMapping[] = [
    {
      field: 'default',
      sortColumn: 'default',
    },
  ];

  private investmentActions: any[] = [
    { label: 'Add New Investment', code: 'community-investment' },
  ];

  protected mounted() {
    this.mount();
  }

  protected changeCurrentOrg() {
    this.mount();
  }

  protected newItem() {
    this.newShowExpense++;
    this.editedId = '';
    this.bEditExpense = true;
  }

  private getHeaders() {
    return this.beneficiaryId ? this.beneficiaryHeaders : this.projectId ? this.projectHeaders : this.payeeHeaders;
  }

  private updateFacets(facets: any) {
    this.getExpenses();
  }

  private voidExpense(id: string, etag: string) {
    this.deleteExpenseEtag = etag;
    this.deleteExpenseId = id;
    this.bDeleteExpenseModal = true;
  }

  private performDeleteExpense() {
    deleteExpense(this.deleteExpenseId, this.deleteExpenseEtag)
    .then((response) => {
      this.closeDelete();
    }).catch((error) => {
      handleError(this.arrErrors, error);
    });
  }

  @Watch('pagination', { deep: true })
  private onPaginationChanged(val: any, oldVal: any) {
    if (!_.isEqual(val, oldVal) && !this.bInitialLoad) {
      if (!this.bInitialLoadWatch) {
        this.getExpenses();
      }
    }
    this.bInitialLoad = false;
    this.bInitialLoadWatch = false;
  }

  private editExpense(id: string) {
    this.editedId = id;
    this.bEditExpense = true;
  }

  private closeEditExpense() {
    this.editedId = '';
    this.bEditExpense = false;
  }

  private saveExpense() {
    this.closeEditExpense();
    this.getExpenses();
    this.$emit('save');
  }

  private getExpenses() {
    let excludeDiversityFacets: boolean = false;
    this.expensesLoading = true;
    if (this.isContribution) {
      this.expensesSearchCriteria.expenseClasses = [ 'contribution' ];
    }
    if (this.projectId && this.projectId !== '') {
      this.expensesSearchCriteria.projects = [ this.projectId ];
    }
    if (this.payeeId && this.payeeId !== '') {
      this.expensesSearchCriteria.payees = [ this.payeeId ];
      excludeDiversityFacets = true;
    }
    if (this.beneficiaryId && this.beneficiaryId !== '') {
      this.expensesSearchCriteria.beneficiaries = [ this.beneficiaryId ];
      this.expensesSearchCriteria.expenseClasses = [ 'services', 'contribution', 'goods', 'equipment', 'unknown' ];
    }
    searchExpenses(this.mapSearchParams(this.pagination, this.searchTerms, this.sortMappings), this.searchCriteria, this.expensesSearchCriteria, excludeDiversityFacets)
    .then((response) => {
      this.expenses = response;
      // On the first unfiltered response set the facets.
      if (this.bInitialLoad) {
        setExpenseSearchFacets(this.searchCriteria, this.expenses, [ this.helper.expenseClasses as ReferenceData, this.helper.expenseTypes as ReferenceData ], this.payeeHelper.diversityReferenceData, this.helper.projects);
      }
    }).catch((error) => {
      handleError(this.arrErrors, error);
    }).finally(() => {
      this.expensesLoading = false;
      this.bInitialLoad = false;
    });
  }

  private mount() {
    this.bInitialLoad = true;
    this.setSearchCriteria();
    this.payeeHelper.getDiversityReferenceData();
    this.helper.getProjects(this.currentOrg, false, false);
    this.helper.getExpenseKindsAll();
    this.helper.getExpenseClasses();
    this.helper.getExpenseTypes();
    this.getExpenses();
  }

  private setSearchCriteria() {
    this.searchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);
    this.expensesSearchCriteria = _.cloneDeep(EmptySearchExpenses);
    // Set the Expense Area search filter.
    this.searchCriteria.filters.push(getExpenseAreaFilter());
  }

  private updateFacetsOrFilters(facets: any) {
    this.getExpenses();
  }

  private getKey() {
    return this.editedId + this.newShowExpense;
  }

  private handleError(error) {
    handleError(this.arrErrors, error);
  }

  private getDeleteIcon(): string {
    return getIconUrl('icon_delete.png');
  }

  private closeDelete() {
    this.bDeleteExpenseModal = false;
    this.$emit('delete');
    this.mount();
  }

  private getImportUrl(): string {
    return '/expenses/import';
  }
}
